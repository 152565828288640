import React, { useState } from 'react'

function FAQ({ className, faqPosts }) {
  const [showQues, setQues] = useState(1)
  const openQuestion = (value) => {
    setQues(value)
  }

  if (!faqPosts?.length) {
    return <></>
  }

  const nrPosts = faqPosts.length
  const splitNumber = Math.ceil(nrPosts/2)
  const faqPostsLeft = faqPosts.slice(0,splitNumber)
  const faqPostsRight = faqPosts.slice(splitNumber)
  
  return (
    <>
      <section className={`appie-faq-area pb-95 pt-95 ${className || ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Vanligt ställda frågor</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  {faqPostsLeft.map((faq, i) => 
                    <div
                    key={i}
                    onClick={() => openQuestion(i+1)}
                    className={`accrodion ${showQues === i+1 ? 'active' : ''}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>{faq.question}</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === i+1 ? 'block' : 'none',
                        }}
                      >
                        <div className="inner">
                          <p>{faq.answer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  {faqPostsRight.map((faq, i) => 
                    <div
                    key={i}
                    onClick={() => openQuestion(splitNumber+i+1)}
                    className={`accrodion ${showQues === splitNumber+i+1 ? 'active' : ''}`}
                    >
                      <div className="accrodion-inner">
                        <div className="accrodion-title">
                          <h4>{faq.question}</h4>
                        </div>
                        <div
                          className="accrodion-content"
                          style={{
                            display: showQues === splitNumber+i+1 ? 'block' : 'none',
                          }}
                        >
                          <div className="inner">
                            <p>{faq.answer}</p>
                          </div>
                        </div>
                      </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq-text text-center pt-40">
                <p>
                  Funderar du över något mer?{' '}
                  <a href="mailto:demo@gmail.com">Kontakta oss</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FAQ
